import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Text from '../components/shared/text'
import {BlogWrapper, Content} from "../components/blog/layout-one/blog.stc";


export default () => (
    <Layout headerLayout={2} headerStyle="darkHeader">
        <SEO title="Atlasopen - Privacy Policy" description="Privacy Policy"/>
        <BlogWrapper>
            <Content>
                <h1>Atlasopen Pty Ltd – Privacy Policy</h1>
                <Text>
                    This Privacy Policy sets out our commitment to protecting the privacy of your personal information that we collect through this website www.atlasopen.com (Site) or otherwise directly from you.
                    Please read this Privacy Policy carefully.
                    <br></br> <br></br>
                    Please contact us if you have any questions – our contact details are at the end of this Privacy Policy.
                    <br></br> <br></br>
                    You providing us with personal information indicates that you have had sufficient opportunity to access this Privacy Policy and that you have read and accepted it.
                    If you do not wish to provide personal information to us, then you do not have to do so.  However, it may affect your use of this Site or any products and services offered on it or provided by us.
                    There are many aspects of the site which can be viewed without providing personal information, however, to get a quote or to contact us you are required to submit personally identifiable information.
                    <br></br> <br></br>
                    Your information will only be shared with another organisation if there is a need to do so, for example, we may utilise a 3rd party API or service in constructuring your software. We would ask for your permission in doing so.
                    We may disclose your data to third party vendors, service providers, contractors or agents who perform functions on our behalf, such as website management, hosting, technical support and marketing analytics. We’ll make sure that they treat it as protective as we do.
                    <br></br> <br></br>
                    For each visitor to reach our site, we expressively collect the following non-personally identifiable information, including but not limited to browser type, version and language, operating system, pages viewed while browsing the Site, page access times and referring website address. This collected information is used solely for internal purposes including to gauge visitor traffic, trends and delivering personalised content to you while you are on the Site.
                    From time to time, we may use customer information for new, unanticipated users not previously disclosed in our privacy notice. If our information practices change at some time in the future we will use for these new purposes only, data collected from the time of the policy change forward will adhere to our updated practices.
                    <br></br> <br></br>
                    Atlasopen reserves the right to make amendments to this Privacy Policy at any time. If you have objections to the Privacy Policy, you should not access or use the Site.
                    <br></br> <br></br>
                    We may use cookies on this Site from time to time. Cookies are text files placed in your computer’s browser to store your preferences. Cookies, by themselves, do not tell us your e-mail address or other personally identifiable information. However, once you choose to furnish the Site with personally identifiable information, this information may be linked to the data stored in the cookie.
                    We may use web beacons on this Site from time to time. Web beacons or clear .gifs are small pieces of code placed on a web page to monitor the visitors’ behaviour and collect data about the visitors viewing a web page. For example, web beacons can be used to count the users who visit a web page or to deliver a cookie to the browser of a visitor viewing that page.
                    <br></br> <br></br>
                    Providing us with your personal information is optional to you.   You can choose not to provide personal information.  When you provide us with your personal information, you consent to the terms in this Privacy Policy, and to us disclosing or receiving your personal information for these purposes.


                </Text>
            </Content>
            </BlogWrapper>
    </Layout>
)
